"use client";

import { Link, useTranslations } from "@packages/i18n";
import { Button } from "@packages/ui";

export const CommunityButton = ({ id, community }) => {
  const t = useTranslations();
  return (
    <Button asChild isFullWidth size="l">
      <Link
        // Passing the page query param in the loginRedirect url ensures that the user will be redirected to the loginRedirect page even if they are a new user
        href={`/login?redirect=${encodeURIComponent(`/communities/join?id=${id}&community=${encodeURIComponent(community.name)}&page=join-community&promoCode=${encodeURIComponent(community.current_contract?.code ?? null)}`)}`}
      >
        {t("community_cta_join")}
      </Link>
    </Button>
  );
};
